import attributeReference from './AttributeReference.json';
import attributeReferenceDisplay from './AttributeReferenceDisplay.json';

/**
 * Returns a list of available attributes based on the AttributeReference.json.
 * @returns {Object} List of available attributes.
 */
export function getAvailableAttributes() {
return attributeReference;
}

/**
 * Returns the display name of an attribute based on the AttributeReference.json.
 * @param {string} attribute - The attribute key.
 * @returns {string} The display name of the attribute.
 */
export function getAttributeDisplayName(attribute) {
    const attributeKey = typeof attribute === 'object' ? attribute.type : attribute;
    return attributeReferenceDisplay[attributeKey]?.display_name || attributeKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) || attribute;
}

/**
 * Returns the description of an attribute based on the AttributeReferenceDisplay.json.
 * @param {string} attribute - The attribute key.
 * @returns {string} The description of the attribute.
 */
export function getAttributeDescription(attribute) {
  return attributeReferenceDisplay[attribute]?.description || 'No description available.';
}