import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useLazyBacktestPreviewQuery } from '../../../store/indexOneApi';
import { Container,Box,LinearProgress,Button,Alert,Link,AlertTitle,Stack,Paper,List,ListItem,IconButton } from '@mui/material';
import Grid from "@mui/material/Grid2"
import WeightingTable from "./WeightingTable"
import {uploadFile,downloadFile, parseUniverseFile} from "../../helpers/fileHelper"
import FileUploadDialog from "./FileUploadDialog"
import FileDownloadTable from './FileDownloadTable';
import UniverseDataGrid from './UniverseDataGrid';
import VariableCreate from './VariableCreate';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ClearIcon from '@mui/icons-material/Clear';

import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, useSortable, arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { getAvailableAttributes,getAttributeDisplayName,getAttributeDescription } from '../../helpers/attributeHelper';

const SortableItem = ({ item, onRemove, onChangeFilter }) => {
  const [showVariableCreate, setShowVariableCreate] = useState(false);
  const [showOperatorCreate, setShowOperatorCreate] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onChangeAttribute = (newAttribute) => {
    onChangeFilter(item.id, newAttribute);
  };
  
    return (
      <Paper ref={setNodeRef} {...attributes} elevation={3} sx={{ padding: 2, width: "100%", ...style }}>
        <Grid container spacing={2} alignItems="center" justifyContent={"space-between"}>
          <Grid item xs="auto">
          <DragIndicatorIcon {...listeners} sx={{ cursor: "pointer" }}/>
          </Grid>
          <Grid item xs>
            <Button onClick={e=>setShowVariableCreate((prev) => !prev)}>{getAttributeDisplayName(item.attribute)}</Button>
          </Grid>
          <Grid item xs>
            <Button onClick={e => setShowOperatorCreate((prev) => !prev)}>{item.operator}</Button>
            {showOperatorCreate && (
              <select
                value={item.operator}
                onChange={(e) => onChangeFilter(item.id, { ...item.attribute, operator: e.target.value })}
              >
                <option value="equals">Equals</option>
                <option value="not_equals">Not Equals</option>
                <option value="greater_than">Greater Than</option>
                <option value="less_than">Less Than</option>
              </select>
            )}
          </Grid>
          <Grid item xs>
          {JSON.stringify(item)}
          </Grid>
          <Grid item xs="auto">
          <IconButton aria-label="delete" size="small" onClick={() => onRemove(item.id)}>
            <ClearIcon/>
          </IconButton>
          </Grid>
        </Grid>
        {showVariableCreate && <VariableCreate attribute={item.attribute} onChangeAttribute={onChangeAttribute}/>}
      </Paper>
    );
  };



export default function CustomUniverse(props) {

    const [uploadDialogOpen,setUploadDialogOpen] = useState(false)
    const [downloadDialogOpen,setDownloadDialogOpen] = useState(false)
    const [loading,setLoading] = useState(false)
    const [parsedFileData,setParsedFileData] = useState(undefined)
    //const [missing,setMissing] = useState([])
    const [fetchTrigger, { data = {"missing":[]}, error, isLoading, isFetching } , lastPromiseInfo] = useLazyBacktestPreviewQuery()


    const updateSpec = async (metaData) => {

      const newState = {
        file_name : metaData.file_name,
        reconstitution_mode : metaData.has_security ? "file" : props.indexSpecifications.reconstitution_mode == 'file' ? 'default': props.indexSpecifications.reconstitution_mode,
        rebalancing_mode : metaData.has_weight ? "file" : props.indexSpecifications.rebalancing_mode == 'file' ? 'default': props.indexSpecifications.rebalancing_mode,
        schedule_mode : metaData.has_time ? "file" : props.indexSpecifications.schedule_mode == 'file' ? 'default': props.indexSpecifications.schedule_mode,
        file_config : metaData
      }
      props.setIndexSpecifications((prevState) => ({
        ...prevState,
        ...newState
      }))
    }

    /*const handleDragEnd = (event) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        setItems(arrayMove(items, oldIndex, newIndex));
      }
    };*/

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
          const oldIndex = active.data.current.sortable.index;
          const newIndex = over.data.current.sortable.index
          const newItems = arrayMove(props.indexSpecifications.reconstitution_config.filters, oldIndex, newIndex);

          // Update the filters array in indexSpecifications
          const updatedIndexSpecifications = {
            ...props.indexSpecifications,
            reconstitution_config: {
              ...props.indexSpecifications.reconstitution_config,
              filters: newItems,
            },
          };
          // Call handleChange with the updated indexSpecifications
          props.handleChange(updatedIndexSpecifications);
        }
      };

      const handleRemove = (id) => {
        console.log('remm1')
        const newItems = props.indexSpecifications.reconstitution_config.filters.filter(item => item.id !== id);
    
        // Update the filters array in indexSpecifications
        const updatedIndexSpecifications = {
          ...props.indexSpecifications,
          reconstitution_config: {
            ...props.indexSpecifications.reconstitution_config,
            filters: newItems,
          },
        };
        console.log('remmm')
        // Call handleChange with the updated indexSpecifications
        props.handleChange(updatedIndexSpecifications);
      };

      const onChangeFilter = (id, newFilter) => {
        const newItems = props.indexSpecifications.reconstitution_config.filters.map(item => 
          item.id === id ? { ...item, attribute: newFilter } : item
        );

        // Update the filters array in indexSpecifications
        const updatedIndexSpecifications = {
          ...props.indexSpecifications,
          reconstitution_config: {
        ...props.indexSpecifications.reconstitution_config,
        filters: newItems,
          },
        };

        // Call handleChange with the updated indexSpecifications
        props.handleChange(updatedIndexSpecifications);
      };


  return (
    <Container maxWidth={false} sx={{height:'100%',padding:'2em'}}>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={props.indexSpecifications.reconstitution_config.filters}>
        <Stack spacing={2}>
          {props.indexSpecifications.reconstitution_config.filters.map((item) => (
            <SortableItem key={item.id} item={item} onRemove={handleRemove} onChangeFilter={onChangeFilter}/>
          ))}
        </Stack>
      </SortableContext>
        </DndContext>
    </Container>
  );
}