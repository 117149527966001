import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ContactForm from '../../elements/ContactForm'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid2'
import Button from '@mui/material/Button'

//accirdion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//fab
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


//card
import Paper from '@mui/material/Paper' 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import StarIcon from '@mui/icons-material/StarBorder';


//images
import isignup1 from '../../../images/pillar/signup1.jpg'
import isignup2 from '../../../images/pillar/signup2.jpg'
import isignup3 from '../../../images/pillar/signup3.jpg'
import isignup4 from '../../../images/pillar/signup4.jpg'
import isignup5 from '../../../images/pillar/signup5.jpg'
import isignup6 from '../../../images/pillar/signup6.jpg'

import i1a from '../../../images/pillar/1a.jpg'
import i1b from '../../../images/pillar/1b.jpg'
import i1c from '../../../images/pillar/1c.jpg'
import i2a from '../../../images/pillar/2a.jpg'
import i3a from '../../../images/pillar/3a.jpg'
import i3b from '../../../images/pillar/3b.jpg'
import i3c from '../../../images/pillar/3c.jpg'
import i3d from '../../../images/pillar/3d.jpg'

import infographic_1 from '../../../images/pillar/infographic_1.jpg'
import infographic_2 from '../../../images/pillar/infographic_2.jpg'
import infographic_3 from '../../../images/pillar/infographic_3.jpg'
import infographic_4 from '../../../images/pillar/infographic_4.jpg'
import infographic_5 from '../../../images/pillar/infographic_5.jpg'
import broadridge from '../../../images/pillar/broadridge.png'
import directindexing from '../../../images/pillar/infographics_directindexing.jpg'
import divsetf from '../../../images/pillar/di_vs_etfs.png'




//comment


//tab
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Vrpano } from '@mui/icons-material';




const commonTerms = {
  "Custom indexing": "custom indexing is the process of setting specific parameters on the stocks you’d like to invest money in, allowing you to personalize your investments based on your individual values, goals, preferences, risk tolerance and tax positioning.",
  "Direct indexing": "direct indexing is an investing strategy that involves purchasing the individual stocks within an index, maintaining the same weights in the index.",
  "Active management": "active management involves an investment manager making investment decisions by tracking the performance of an investment portfolio.",
  "Passive management": "passive management involves a fund’s portfolio mirroring a market index, by selecting stocks to be included in a portfolio, unlike active management. ",
  "Market index": "A market index is a hypothetical portfolio that contains investment holdings. The value of a market index is based on the prices of the underlying holdings. ",
  "Efficient market hypothesis": "EHM, or efficient market hypothesis is a theory coined by Eugene Farma, which states that active managers can beat the market only for a given period of time, as their success is simply a matter of chance. EHM suggests that long-term passive management delivers better results than asset management. ",
  "Active investing": "active investing involves the ongoing buying and selling of securities by monitoring market index.",
  "Passive investing": "passive investing is a long-term strategy that involves buying securities that mirrors a market index.",
  "Thematic investing": "thematic investing focuses on investing in long-term or macro-level trends. Examples of thematic investment themes include water, robotics & AI, gaming & e-sports, and space exploration.",
  "ESG investing": "ESG investing emphasizes on investments that prioritizes optimal environmental, social and governance outcomes.",
  "Factor investing": "factor investing is a type of portfolio management strategy that targets quantifiable metrics or factors that can explain differences in stock returns. These factors often include value, size, volatility, momentum, and quality. ",
  "ETF": "exchange traded funds, or ETFs refer to a type of investment fund that is traded on a stock exchange. An ETF usually tracks a generic market index and allows an investor to potentially lower risks and exposure, while diversifying their portfolio.",
  "RIA": "A registered investment advisor, or RIA, is an individual or firm that advises clients on investment decisions and manages their investment portfolios.",
  "Backtesting": "backtesting allows an investor to test an investment strategy using historical data to assess how it would have performed before earning actual returns.  ",
  "Rebalancing": "rebalancing involves the process of realigning the weightings of assets within a portfolio, by buying or selling the assets to maintain the original or desired level of asset allocation or risk. ",
  "Systematic portfolio": "a systematic portfolio contains securities that maintains a price higher than the predetermined level by a systematic manager. A systematic portfolio strategy invovvles trading decisions based on market price trends. ",
  "Rules-based strategies": "a rules-based investment strategy follows smart investment rules and aims to deliver active returns in a cost-efficient manner.",
  "Active returns": "an active return is the percentage difference between a benchmark and the actual return.",
  "Index provider": "an index provider is a firm that creates, calculates and maintains market indices based on any given investment strategy. ",
  "Sustainable investing": "sustainable investing is a type of investment strategy that prioritizes environmental, social and corporate governance impacts before investing in a particular company, venture or  fund. It is also called ESG investing or SRI.",
  "Investment strategy": "an investment strategy is a set of principles that guide an investor to make sound investment decisions based on their financial goals, values, risk tolerance and preferences. ",
  "Alpha investment strategies": "alpha strategies are active investment strategies that choose investments that have the potential to beat the market. Alpha is also known as “excess returns” or “abnormal rate of return.”",
  "Benchmarking": "benchmarking is the process of setting a standard against which the performance of an investment strategy can be measured. ",
  "Reconstitution": "reconstitution is the re-evaluation of a market index to ensure that an index reflects up-to-date market cap and is balanced. ",
  "Bonds": "a bond is a type of security where the issuer of a bond owes the holder of the bond a debt, and the issuer is obligated to repay the principal of the bond at the maturity date, as well as interest on the bond. ",
  "Asset allocation": "asset allocation is the process of dividing an investment among different types of assets, such as stocks, bonds and cash. ",
  "Quant": "quant, or quantitative analysis, is the process of using mathematical and statistical methods to make investment decisions.",
  "Index Funds": "index funds are a type of mutual fund or exchange-traded fund (ETF) that aim to replicate the performance of a specific market index.",
  "Mutual Funds": "a mutual fund is a type of investment fund that pools money from several investors to purchase securities. ",
  "Derivatives Structured Products": "derivative structured products are financial instruments that combine derivatives with other underlying assets to create investment products with unique risk and return characteristics.",
  "Hedge Funds": "similar to mutual funds, a hedge fund is a type of pooled investment fund that trades in relatively liquid assets. Hedge funds primarily use portfolio construction, complex trading and risk management techniques in an attempt to improve performance. ",
  "Index Front-running": "traders who watch market prices know when an index fund will update its components, allowing them to front-run the trade by buying or selling the shares to get ahead of the market and gain an edge. This is not considered illegal because it rewards individuals who pay close attention to information that already exists in the market. However, SEC Rule 17(j)-1 prohibits insiders from taking advantage of their knowledge of client trades for personal gain."
}

const faqs = {
  "What is index construction?": "Index construction refers to the process of creating and maintaining a market index, which is a hypothetical portfolio of securities that represents a specific segment of the overall market.",
  "What is the difference between custom indexing vs direct indexing?": "While direct indexing requires you to choose amongst pre-packaged solutions, custom indexing allows for unlimited customization within different factors, allowing investors to build a portfolio that truly reflects their unique investment goals and preferences. ",
  "What is the difference between active investing vs passive investing?": "While active investing strategies focus on individual securities and a more hands-on approach, passive investing strategies tend to focus on purchasing shares of index funds or ETFs in an attempt to mirror or beat the performance of market indexes.",
  "What is the difference between securities vs stocks?": "A security is the ownership or debt with value. A stock is a type of security that gives the holder of the stock ownership or equity of the publicly-traded company.",
  "What is the difference between stocks vs shares?": "A share is a unit of ownership measured by the number of shares you own, whereas a stock is a unit of equity, measured by the percentage of ownership of the company.",
  "What is the difference between portfolio manager vs asset manager?": "A portfolio manager handles investments and other financial products that make up a portfolio. An asset manager may also manage portfolios, but they mainly handle cash and assets, which a portfolio manager does not. ",
  "What is the difference between Index vs Benchmark?": "While a benchmark only serves as a standard to measure index performance against, an index is created for a variety of reasons, and one of its purposes is to act as a benchmark. In other words, a benchmark is usually always an index, but an index doesn’t necessarily have to be a benchmark.",
  "What is the difference between reconstitution vs rebalancing?": "Rebalancing is a more automated process where price and market-cap weighted indices are rebalanced automatically. Reconstitution, on the other hand, requires the manual adding and removal of securities from an index, based on whether or not these securities are meeting index criteria.",
  "What is the difference between an index fund vs ETF": "An index fund is a mutual fund which tracks an index, while an ETF is an exchange traded asset tracking the performance of an index.",
  "Can you invest directly in an index?": "An index is a hypothetical basket of stocks. In order to invest in an index, it would need to be an investable product that tracks an index. A few examples of an investable product are mutual funds and ETFs. ",
  "How does index rebalancing work?": "Index rebalancing refers to the process of adjusting the composition and weights of securities within an index. It is typically done periodically to maintain the index's target representation and desired characteristics. Index rebalancing helps maintain the integrity of the index and ensures that it continues to accurately reflect the targeted market segment. It allows for adjustments to account for changes in market conditions, company fundamentals, and other factors that may affect the composition and weights of the index components. ",
  "How to create a stock index?": "Creating a stock index involves several steps and considerations, including defining the index objective, selecting the index components, determining the weighting methodology, setting the initial index values, establishing the index calculation methodology, regular maintenance and rebalancing, index calculation and dissemination and index governance and oversight. ",
  "How to construct an index for research?": "Constructing an index for research purposes involves a tailored approach to meet specific research objectives. This includes defining research objectives, selecting the relevant securities, determining inclusion and exclusion criteria, determining weighting methodology, setting the index universe, establishing index calculation methodology, data collection and management, performing backtesting and validation, documenting index construction methodology and analyzing and interpreting results. ",
  "How to create your own index?": "Creating your own index requires careful consideration of various factors, including your investment objectives, the availability of data, and the resources needed to maintain and calculate the index. It may be beneficial to seek professional advice or consult with experts in index construction to ensure the integrity and accuracy of your self-created index.",
  "How to make your own index fund?": "Creating your own index fund involves several steps and considerations: define the investment objective, select the index components, determine the weighting methodology, set the initial fund composition, establish a rebalancing strategy, implement the fund's portfolio, track and monitor performance, consider legal and regulatory requirements, consider administration and custody, develop a distribution strategy, and comply with reporting and governance. "
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});



export default function Pillar(props) {
  
  const navigate = useNavigate()
  document.title = `The Ultimate Guide to Direct Indexing | Index One`
  const [selected, setSelected] = useState('fa')

  const returnImage = (img, link, altText="index one") => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center' sx={{ marginTop: '5em', marginBottom: '5em' }}>
        <a href={link}><img alt={altText} style={{ display: 'block', maxHeight: '500px', maxWidth: '700px', height: 'auto', width: 'auto' }} src={img} /></a>
      </Stack>
    )
  }

  const returnInfographic = (img, link, altText="index one") => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <a href={link}><img alt={altText} style={{ display: 'block', maxHeight: '500px', maxWidth: '600px', height: 'auto', width: 'auto' }} src={img} /></a>
      </Stack>
    )
  }

  const returnVideo = (embedId) => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Stack>
    )
  }



  return (
    (<Container component="main" disableGutters maxWidth={false}>
      <a id="top" />
      <Fab sx={{ position: 'fixed', bottom: 20, right: 20 }} color="primary" aria-label="scroll-up" size='small' href='#top'>
        <ArrowUpwardIcon />
      </Fab>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ backgroundColor: '#343a40', minHeight: '40vh', marginTop: 0, padding: '5em' }}>
        <Typography style={{ color: '#fafafa', margin: '1rem', textAlign: 'center' }} variant="h3">The Ultimate Guide to Direct Indexing </Typography>
        <br />
        <Container style={{ textAlign: 'center' }}>
          <Typography style={{ color: '#fafafa', margin: '1rem', marginBottom: '2rem' }} variant="h5">Learn everything you need to know about direct indexing,<br /> tax-loss harvesting, and why investors are increasingly considering direct indexing strategies.</Typography><br />
        </Container>
      </Box>
      <div style={{ clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
        <Container disableGutters maxWidth='lg'>
          <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>What is Direct Indexing?</Typography>
        </Container>
      </div>
      <Stack direction='column'>
        <Container style={{ textAlign: 'center' }}>
          <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>
            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em'}}>
              <b>Direct indexing</b> is an investment strategy that allows investors to directly own a portfolio of individual stocks or securities that replicate the performance of a specific index, such as the S&P 500, rather than investing in a traditional index fund or exchange-traded fund (ETF). 
<br/><br/>With direct indexing, investors can <b>customize their portfolios</b> by selecting and weighting individual stocks to align with their financial goals, values, and tax considerations.
<br/><br/><br/><br/>

              </Paper>
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em'}}>
              In a survey conducted by Broadridge Financial Advisors in September 2022, findings reveal that merely 44% of financial advisors have a "proficient" understanding of direct indexing, with a mere 16% presently integrating it into their clients' portfolios.
<br/><br/>Despite this relatively low initial uptake, there's an evident trend toward increasing adoption. Among firms that possess knowledge about direct indexing, <b>a remarkable 93% have already incorporated it into their services or have concrete plans to do so.</b> Additionally, a significant 84% of respondents from larger financial firms have already established a direct indexing solution in the marketplace.

              </Paper>
            </Grid>
          </Grid>
          <Grid>{returnInfographic(broadridge, '/register')}</Grid>
          <Typography variant='h10' style={{ textAlign: 'center', margin: '1rem' }}>Via <a href="https://www.broadridge.com/_assets/pdf/broadridge-preparing-for-the-next-evolution-in-passive-investing-direct-indexing-whitepaper.pdf">Broadridge Financial Advisors</a></Typography>
        <br/><br/><br/>
          <Paper sx={{padding:'2em'}}>
              <b>Define direct indexing:</b>
              <br/>
              The terms custom indexing and direct indexing are sometimes used interchangably.<br/><br/> 
              Direct indexing refers to an investment approach that allows investors to build a portfolio that closely replicates the performance of a specific benchmark index.
              <br/><br/>
              In <b>direct indexing</b>, an investor constructs a portfolio by purchasing the underlying securities of the index in the same weightings as the benchmark. On the other hand, <b>custom indexing</b> refers to the creation of a unique index that is specifically designed to meet certain criteria or objectives. 
              </Paper>
              <br/><br/>

              <Paper sx={{padding:'2em'}}>
              <b>Direct indexing for advisors:</b>
              <br/>
              Direct indexing can be a valuable tool for registered investment advisors (RIA) and portfolio managers in managing their clients' portfolios, promoting customization, tax efficiency, enhanced control, cost considerations and client education. 
              <br/><br/>
              This is facilitated by modern <a href="https://indexone.io/indexbuilder">direct indexing technology</a>, enabling RIAs and portfolio managers to provide custom and direct indexing strategies in separately managed accounts, a personalized service and value-add to their clients.
              </Paper>
          <br/><br/>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Direct indexing software:</b><br />
             Index One provides clients with a state-of-the-art and comprehensive direct indexing solution that caters to various use cases witihn the financial industry.
                  <br/><br/>
                <a href="https://indexone.io/indexbuilder">Explore our index builder.</a>
              </Paper>

        </Container>

        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Direct Indexing vs. ETFs vs. Custom Indexing</Typography>
            </Container>
          </div>
        </Stack>

        <Stack direction='column'>
          <Container style={{ textAlign: 'center' }}>
            <Typography>
            Direct indexing and <a href="https://indexone.io/customindexing">custom indexing</a> are often used interchangeably, but they have distinct differences. With the rise of both direct indexing and ETF strategies, it is becoming increasingly important to understand the distinctions between these two investment vehicles.
            <br />
            <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em', justifyContent:'center'}}>
            <Grid
              size={{
                xs: 6,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Direct indexing</b> involves owning individual stocks or fractional shares from a benchmark index.
              </Paper>
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Custom indexing</b> involves creating a unique index composed of specific stocks or assets that may not necessarily mimic an established market index.
              </Paper>
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>ETFs</b> can be either passive funds that track a market index, or actively managed funds that aim to outperform the market. 
              </Paper>
            </Grid>
            <Grid>{returnInfographic(divsetf, '/register')}</Grid>
          </Grid>
            </Typography>
          </Container>
        </Stack>
        <Grid
          Item
          sx={{textAlign:'center'}}
          size={{
            xs: 12,
            sm: 8
          }}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">create your own basket of stocks</Button>
            </Grid>





        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Example of Direct Indexing</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>
            <Grid container spacing={5}>
              <Grid>{returnInfographic(directindexing, '/register')}</Grid>

              <Grid
                size={{
                  sm: 4
                }}><Typography>
              Several registered investment advisors and portfolio managers provide direct indexing for a number of reasons such as tax-loss harvesting and <a href="https://www.linkedin.com/pulse/how-provide-personalized-service-direct-indexing-index-sri-rangan/?trackingId=3LwQXMHTTSOXtxFYcV8FKA%3D%3D">personalization.</a> 
                <br /><br />
                <b>Direct indexing offers these benefits by allowing investors to own individual stocks from an index.</b> For example, if an investor wants to invest solely on tech stocks that are more ESG-oriented, they are able to do so through direct indexing, while mimicking the performance of a tech market index. 
                <br /><br />
                The investor can also harvest losses by selling securities at a loss strategically to mitigate the capital gains tax liability incurred from the sale of profitable assets in a timely manner, which is called <a href="https://www.linkedin.com/pulse/tax-loss-harvesting-direct-indexing-index-one-gayathiri-sri-rangan/?trackingId=oLUTnLAcQLK9Q7ukW02RAw%3D%3D">“tax-loss harvesting.”</a>
                <br/>
                <br/>
              </Typography></Grid>
            </Grid>
<br/><br/>
            <Paper align='center' sx={{padding:'2em',height:'100%'}}>
              There are several direct index solutions on the market, including Index One's low-cost index calculation platform that provides a holistic indexing solution for financial professionals.<br/>
              <br/>
              <Stack direction='row' justifyContent='center'>

<Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn More</Button>

</Stack> </Paper>

              

          </Container>
        </Container>
        

        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
          <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>What is tax-loss harvesting?</Typography>
              </Container>
            </Stack>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography>
                While not all investments yield profits, there is a potential silver lining when faced with a losing investment: the opportunity to leverage that loss to reduce your tax obligations and enhance your portfolio's overall positioning.
                <br /><br />
                For example, if an investor notices a substantial increase in the value of their tech holdings, while some of their industrial stocks have experienced a decline in value, their portfolio has become overly exposed to the tech sector in relation to your desired allocation. 
<br /><br />

            <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>
            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              To realign their investments, investors can sell some of their tech stocks and reinvest the proceeds accordingly. 
              </Paper>
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              However, this adjustment results in a notable taxable gain.
              </Paper>
            </Grid>
          </Grid>

<br/><br/>
<b>This is where tax-loss harvesting becomes relevant.</b> By selling the industrial stocks that have depreciated in value, you have the opportunity to utilize those losses to offset the capital gains generated from selling the tech stocks, effectively lowering your tax liability. <a href="https://www.morganstanley.com/articles/what-is-direct-indexing-benefits">(via Morgan Stanley)</a>
                <br />
                <br/>
                <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Leverage custom indexing for your business</Button>
                </Typography>
              </Container>
            </Stack>


            {returnImage(isignup1, '/resources', "Free Download: Index One Overview. Find out how Index One is relevant to your business by discovering our DIY custom indexing capabilities. Download Now button.")}
          </Container>
        </Container>




        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>

            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Benefits of Direct Indexing: Is Direct Indexing worth it​?</Typography>
              </Container>
            </Stack>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography>
                With an increase in demand for direct indexing and an unprecedented number of direct indexing technology solutions, investors and advisors alike are presented with an opportunity to increase efficiency in custom index creation in a cost-effective manner.
           <br/><br/>

            <Stack direction='column' spacing={5}>
            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Customization:</b><br />
              With direct indexing, you own the individual stocks within the index, providing direct exposure to each security. 
                  <br/><br/>
                  Index One provides a flexible tool to build, calculate, maintain and integrate direct indexing strategies. <a href="https://indexone.io/indexbuilder">Find out how.</a>
              </Paper>
 <br/><br/>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Tax Efficiency:</b><br/>
              Direct indexing offers tax benefits through tax-loss harvesting, allowing you to offset gains and reduce tax liability. Direct indexing provides greater control over when to realize gains or losses, optimizing tax strategies.
              </Paper>
              <br/><br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Alignment with Values:</b><br/> Direct indexing offers greater customization, such as excluding specific companies or sectors and applying ESG (Environmental, Social, Governance) filters.
              </Paper>
              <br/><br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Diversification:</b><br/> Direct indexing offers broad market exposure and allows for personalized diversification, such as adjusting sector or geographic weightings based on personal preferences.
              </Paper>
              <br/><br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Customized Rebalancing:</b><br/> Direct indexing enables customized rebalancing to align with individual preferences, such as maintaining sector weightings or risk levels.
              </Paper>
              <br/><br/>
              </Stack>
        
            
                <br />
                <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Leverage custom indexing for your business</Button>
                </Typography>
              </Container>
            </Stack>


            {returnImage(isignup1, '/resources', "Free Download: Index One Overview. Find out how Index One is relevant to your business by discovering our DIY custom indexing capabilities. Download Now button.")}
            
            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Custom indexing solutions:</b><br />
              Custom indexing solutions refer to specialized index construction and management services tailored to meet the specific needs of institutional clients, asset managers, or investors. These solutions offer flexibility and customization beyond standard off-the-shelf indices.
                  <br/><br/>
                  Index One provides a flexible tool to build, calculate, maintain and integrate custom indices. <a href="https://indexone.io/indexbuilder">Find out how.</a>
              </Paper>

          </Container>
        </Container>
        



        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>

            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Drawbacks of Direct Indexing</Typography>
              </Container>
            </Stack>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='column' spacing={3}>
              <Container style={{ textAlign: 'center' }}>
                <Typography>
                Due to the many complexities of managing an index product, maintaining custom index products for individual investors at scale can be challenging using existing technology solutions.
           <br/><br/>

            <Stack direction='column' spacing={5}>
            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Complexity:</b><br />
              Direct indexing can be more complex than traditional index investing, requiring careful stock selection and portfolio management.
              </Paper>
 
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Higher Costs:</b><br/>
              For smaller portfolios, trading individual stocks can be costlier due to brokerage fees and spreads.
              </Paper>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Risk:
                </b><br/> Since direct indexing involves holding individual stocks, direct indexing carries more stock-specific risk compared to a diversified fund.
              </Paper>
              <br/><br/>
              </Stack>
        
                <br />
<Typography>With modern index technology providers, investors are given full autonomy to <b>build a “do-it-yourself” index in a simple and straightforward manner.</b></Typography>
<br/>
                <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button>
                </Typography>
              </Container>
            </Stack>


            {returnImage(isignup1, '/resources', "Free Download: Index One Overview. Find out how Index One is relevant to your business by discovering our DIY custom indexing capabilities. Download Now button.")}

          </Container>
        </Container>





        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Direct Indexing for Tax-Loss Harvesting</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>
            <Grid container spacing={5}>
              <Grid>{returnInfographic(directindexing, '/register')}</Grid>

              <Grid
                size={{
                  sm: 4
                }}><Typography>
              <b>Tax-loss harvesting</b> is a tax-efficient strategy where investors intentionally sell investments that have declined in value to offset gains elsewhere in their portfolio. <br/><br/>
              With direct indexing, investors can easily identify and sell underperforming individual stocks to generate tax losses, helping to <b>reduce their overall tax liability.</b>
                <br/>
                <br/>
                <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button>
              </Typography></Grid>
            </Grid>
<br/><br/>


          </Container>
        </Container>


        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>How does Direct Indexing work​?</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>
           
              

              <Grid
                size={{
                  sm: 4
                }}><Typography>
              To implement direct indexing:
                <br/>
                <br/>
                <Grid container spacing={3} alignItems='stretch'>
            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Select an Index:</b><br/>Choose the <a href="https://indexone.io/indices">benchmark index</a> you want to replicate, or create a <a href="https://indexone.io/customindexing">custom index</a>.
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b><a href="https://indexone.io/indexbuilder"> Build the Portfolio</a>:</b><br/>Purchase individual stocks in the same proportion as their weights in the chosen index.
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Portfolio Management:</b><br/> Continuously monitor and rebalance the portfolio to maintain the desired asset allocation.
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Consider Tax Strategies:</b><br/> Implement tax-efficient strategies like tax loss harvesting.
              </Paper>
            </Grid>
            </Grid>
                <br/>
                Index One has partnered with a number of direct indexing platforms that enables investors to deploy tax-loss harvesting in direct indexings strategies. <a href="https://indexone.io/contact">Learn more.</a>
              </Typography></Grid>
              <br/><br/>
              <Grid
                Item
                sx={{textAlign:'center'}}
                size={{
                  xs: 12,
                  sm: 8
                }}>
            <Button onClick={e => navigate(`/indices`)} variant="outlined" size="large">View all indices</Button>
            </Grid>


          </Container>
        </Container>







        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Passive Index Investing</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>

              <Grid
                size={{
                  sm: 4
                }}><Typography>
              <Paper sx={{padding:'2em',height:'100%'}}>
              Passive index investing involves buying and holding a diversified portfolio that closely mirrors a specific market index. 
              
              <br/><br/>It aims to capture the overall market's performance, typically with <b>low costs and minimal turnover.</b>
              </Paper>
              </Typography></Grid>

          </Container>
        </Container>






        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Direct Indexing Strategies</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>
        
            

              <Grid
                size={{
                  sm: 4
                }}><Typography>
              Direct indexing strategies can vary based on an investor's goals, but common approaches include:
                <br/>
                <br/>
                <Grid container spacing={3} alignItems='stretch'>
            <Grid
              size={{
                xs: 12,
                sm: 4
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Factor Investing:</b><br/>Overweighting or underweighting stocks based on specific factors like <a href="https://indexone.io/indices">value, growth, or dividend yield.</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 4
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>ESG Integration:</b><br/>Constructing a portfolio with companies that align with <a href="https://indexone.io/indices">environmental, social, and governance principles.</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 4
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Tax Optimization:</b><br/>Focusing on tax-efficient trading strategies, like tax loss harvesting and tax-efficient asset location.
              </Paper>
            </Grid>
            </Grid>
              </Typography></Grid>
          
<br/><br/>


          </Container>
        </Container>







        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>How to Get Started with Direct Indexing</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>

              
             
              <Grid
                size={{
                  sm: 4
                }}><Typography>
              To get started with direct indexing:
                <br/>
                <br/>
                <Grid container spacing={3} alignItems='stretch'>
            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Define Your Objectives:</b><br/>Determine your investment goals, risk tolerance, and any specific preferences.
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b><a href="https://indexone.io/indices">Select an Index</a>:</b><br/>Choose the benchmark index that aligns with your objectives.
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Choose a Platform:</b><br/>Consider whether to use a brokerage, robo-advisor, or specialized direct indexing platform.
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b><a href="https://indexone.io/indexbuilder">Build Your Portfolio</a>:</b><br/> Purchase individual stocks according to your chosen index.
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 3
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Monitor and Rebalance:</b><br/>Regularly review and adjust your portfolio to maintain your desired asset allocation.
              </Paper>
            </Grid>

            </Grid>
                <br/>
                <br/>
                <Typography>Direct indexing offers investors a unique approach to portfolio construction, allowing for greater customization and tax optimization, but it also requires active management and a solid understanding of the stock market. Before proceeding, consult with a financial advisor to ensure it aligns with your financial goals and risk tolerance.</Typography>
              </Typography></Grid>
            
<br/>

<Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button>

          </Container>
        </Container>

        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>

            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>List of Direct Indexing Strategies</Typography>
              </Container>
            </Stack>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
          <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
              <Stack direction='column' spacing={5}>
          <Container style={{ textAlign: 'center' }}>
            <Typography> Index One powers a number of direct indexing strategies. Check out some direct indexing strategies calculated by Index One.

            <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
             <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/1b96acfd-a6f3-4010-b50c-da5bfc13fecc-0/overview" target="_blank">BX US Large Cap Momentum Index
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/a528c926-abb3-4335-aebb-978264bdfaaa-0/overview" target="_blank">BX/FolioBeyond Dynamic Volatility Fixed Income Index</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/f260f2e9-46ba-4f47-aa22-27bfc6122d7e-0/overview" target="_blank">BX/Thor Next Gen Stock Index
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/a0b96d90-58e7-4a21-ab7c-2d80ee89d803-0/overview" target="_blank">BX Smart Alpha US Large-Cap Value Strategy
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/04221ecb-4b42-41b2-a4bb-c3e31d0c2527-0/overview" target="_blank">BX/Dynalogic Sector Rotation Index
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/21b39a37-f5bc-44a4-81f0-08fc04e38f25-0/overview" target="_blank">BX US SMID Momentum Index
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/cf425d7f-ea88-4365-a19d-d7d2feef8230-0/overview" target="_blank">BX/FT American Disciplined Small Cap Index
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/f397947a-e5d7-4a14-9fd5-028369035e71-0/overview" target="_blank">BX US Equity Focus 10 Index
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/28e8663e-cc47-4a01-ad32-a81d4330bef9-0/overview" target="_blank">BX Triple Screen Momentum Index
</a>
              </Paper>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6
              }}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <a href="https://indexone.io/index/f267647f-b56a-4780-bcfc-919c92de6b6c-0/overview" target="_blank">BX Fantastic Five Trend Capture
</a>
              </Paper>
            </Grid>

          </Grid>
            </Typography>
            <Grid
              Item
              sx={{textAlign:'center'}}
              size={{
                xs: 12,
                sm: 8
              }}>
            <Button onClick={e => navigate(`/indices`)} variant="outlined" size="large">View all indices</Button>
            </Grid>
          </Container>
        </Stack>
              </Container>
            </Stack>
   

            {returnImage(isignup1, '/resources', "Free Download: Index One Overview. Find out how Index One is relevant to your business by discovering our DIY custom indexing capabilities. Download Now button.")}

          </Container>
        </Container>


        {/* ACCORDIONS */}

        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Frequently Asked Questions</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
          {Object.entries(faqs).map(([k, v]) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={k}
                >
                  <Typography>{k}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {v}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Container>
        </Container>



        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Commonly Used Terms</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
          {Object.entries(commonTerms).map(([k, v]) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={k}
                >
                  <Typography>{k}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {v}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
                </Container>
        </Container>

      </Stack>
    </Container>)
  );
}