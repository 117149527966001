import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, Paper, TextField } from '@mui/material';
import Grid from "@mui/material/Grid2"

const categories = {
  descriptive: ['market_cap', 'volume', 'beta'],
  fundamentals: ['p_e_ratio', 'eps', 'roe'],
  technical: ['moving_average', 'rsi', 'macd']
};

const variableConfigs = {
  'market_cap': ['currency'],
  'volume': ['trailing_periods'],
  'beta': ['trailing_periods'],
  'p_e_ratio': ['currency'],
  'eps': ['currency'],
  'roe': ['currency'],
  'moving_average': ['periods', 'type'],
  'rsi': ['periods'],
  'macd': ['fast_period', 'slow_period', 'signal_period'],
};

export default function VariableCreate({ attribute, onChangeAttribute }) {
  const [selectedCategory, setSelectedCategory] = useState('descriptive');
  const [selectedVariable, setSelectedVariable] = useState(null);

  useEffect(() => {
    console.log(attribute);
    if (attribute && attribute.type) {
      const category = Object.keys(categories).find(cat =>
        categories[cat].some(varName => varName === attribute.type)
      );
      setSelectedCategory(category);
      setSelectedVariable(attribute.type);
    }
  }, [attribute]);

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setSelectedVariable(null); // Reset selected variable when category changes
  };

  const handleVariableClick = (variable) => {
    setSelectedVariable(variable);
    onChangeAttribute({ type: variable });
  };

  const handleConfigChange = (param, value) => {
    onChangeAttribute({ ...attribute, [param]: value });
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ borderRight: 1, borderColor: 'divider', width: '20%' }}>
        <Tabs
          orientation="vertical"
          value={selectedCategory}
          onChange={handleCategoryChange}
          aria-label="Variable categories"
        >
          {Object.keys(categories).map((category) => (
            <Tab key={category} label={category} value={category} />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6">Variables</Typography>
              {categories[selectedCategory] && categories[selectedCategory].map((variable) => (
                <Typography
                  key={variable}
                  variant="body1"
                  sx={{ cursor: 'pointer', margin: '8px 0' }}
                  onClick={() => handleVariableClick(variable)}
                >
                  {variable}
                </Typography>
              ))}
            </Paper>
          </Grid>
          {JSON.stringify(attribute)}
          <Grid item xs={6}>
            {selectedVariable && (
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">Config Options for {selectedVariable.replace(/_/g, ' ')}</Typography>
                {variableConfigs[selectedVariable] && variableConfigs[selectedVariable].map((config) => (
                  <TextField
                    key={config}
                    label={config}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={attribute[config] || ''}
                    onChange={(e) => handleConfigChange(config, e.target.value)}
                  />
                ))}
              </Paper>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}