import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import ContactForm from '../../elements/ContactForm';

// Table component for displaying data
const MyTable = ({ searchTerm, toggleTickerOverviewPanel }) => {
    const data = [
        { logo: 'Logo1', ticker: 'AAPL', name: 'Apple Inc.', figi: 'BBG000B9XRY4', mic: 'XNAS', close: '150.00' },
        { logo: 'Logo2', ticker: 'GOOGL', name: 'Alphabet Inc.', figi: 'BBG009S3NB30', mic: 'XNAS', close: '2800.00' },
        { logo: 'Logo3', ticker: 'AMZN', name: 'Amazon Inc.', figi: 'BBG000BVPV84', mic: 'XNAS', close: '3400.00' },
    ];

    // Filter rows based on the search term
    const filteredData = data.filter((row) =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.ticker.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.figi.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Logo</TableCell>
                        <TableCell align="right">Ticker</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">FIGI</TableCell>
                        <TableCell align="right">MIC</TableCell>
                        <TableCell align="right">Close</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((row, index) => (
                        <TableRow key={index} onClick={() => toggleTickerOverviewPanel(index)}>
                            <TableCell>{row.logo}</TableCell>
                            <TableCell align="right">{row.ticker}</TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">{row.figi}</TableCell>
                            <TableCell align="right">{row.mic}</TableCell>
                            <TableCell align="right">{row.close}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

// Ticker Overview Panel component to display selected row details
const TickerOverviewPanel = ({ tickerData, toggleTickerOverviewPanel }) => {
    return (
        <Paper sx={{ height: '60vh', margin: '0px', padding: '0px' }}>
            <Typography variant="h6">{tickerData.name}</Typography>
            <Typography variant="body1">data</Typography>
            <Button
                variant="contained"
                onClick={() => toggleTickerOverviewPanel(null)} // Close the panel
                style={{ marginTop: '20px' }}
            >
                Close
            </Button>
        </Paper>
    );
};

// Main component where everything is integrated
export default function Integrations() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [tickerOverviewPanelOpen, setTickerOverviewPanelOpen] = useState(false);
    const [tickerSelected, setTickerSelected] = useState(null);

    const indices = [
        { logo: 'Logo1', ticker: 'AAPL', name: 'Apple Inc.', figi: 'BBG000B9XRY4', mic: 'XNAS', close: '150.00' },
        { logo: 'Logo2', ticker: 'GOOGL', name: 'Alphabet Inc.', figi: 'BBG009S3NB30', mic: 'XNAS', close: '2800.00' },
        { logo: 'Logo3', ticker: 'AMZN', name: 'Amazon Inc.', figi: 'BBG000BVPV84', mic: 'XNAS', close: '3400.00' },
    ];

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const toggleTickerOverviewPanel = (indexId) => {
        if (indexId !== null) {
            setTickerOverviewPanelOpen(true);
            setTickerSelected(indexId);
        } else {
            setTickerOverviewPanelOpen(false);
            setTickerSelected(null);
        }
    };

    return (
        <Container component="main" disableGutters maxWidth={false}>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ backgroundColor: '#343a40', minHeight: '50vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop: 0 }}>
                <Typography style={{ color: '#fafafa', margin: '1rem' }} variant="h2">Securities</Typography>
                <Typography style={{ color: '#fafafa', margin: '1rem' }} variant="h5">
                    Index One provides financial professionals with easy and flexible API integration capabilities. <br />
                    Find out how you can integrate index data on websites, apps and third-party trading platforms.
                </Typography>
            </Box>
            <Container maxWidth='md' style={{ padding: '50px 1rem' }}>
                <Typography variant='h4' style={{ textAlign: 'center', margin: '1rem' }}>Key Features</Typography>
                
                {/* Search Bar */}
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '1rem' }}
                />

                
                <Box display="flex" justifyContent="space-between" gap="1rem" flexDirection={{ xs: 'column', sm: 'row' }}>
                    {/* Table with filtered data */}
                    <Box flex={1}>
                        <MyTable searchTerm={searchTerm} toggleTickerOverviewPanel={toggleTickerOverviewPanel} />
                    </Box>

                    {/* Ticker Overview Panel */}
                    {tickerOverviewPanelOpen && tickerSelected !== null && (
                        <Box flex={1} minWidth="300px">
                            <Paper sx={{ height: '60vh', padding: '0px', width: '100%' }}>
                                <TickerOverviewPanel
                                    tickerData={indices[tickerSelected]}
                                    toggleTickerOverviewPanel={toggleTickerOverviewPanel}
                                />
                            </Paper>
                        </Box>
                    )}
                </Box>

            </Container>

            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ padding: '100px 0px', marginBottom: '-15vh', backgroundColor: '#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))' }}>
                <ContactForm />
            </Box>
        </Container>
    );
}
