import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"

import IndexPreview from './IndexPreview';
import IndexSetup from './IndexSetup';

//external components
import { CSVLink, CSVDownload } from "react-csv";

import Container from '@mui/material/Container';
import config from '../../../config'

import timezones from '../../helpers/timezones'
import { backtestAllowed,goLiveAllowed } from '../../helpers/authHelper'

import Chip from '@mui/material/Chip';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab'
import { Typography, Stack,  Tooltip, IconButton, Button, Paper } from '@mui/material';
import Grid from "@mui/material/Grid2"
import LinearProgress from '@mui/material/LinearProgress';

import { TextField, MenuItem } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import CodeInterface from '../CodeInterface';
import SecurityDialog from '../SecurityDialog'
import IndexSpecifications from '../indexwizard/IndexSpecifications'
import FilterList from '../indexwizard/FilterList'
import SecuritiesList from '../indexwizard/SecuritiesList'
import Weighting from '../indexwizard/Weighting'
import SimpleChart from '../indexwizard/SimpleChart'
import VolatilityChart from '../indexwizard/VolatilityChart'
import DrawdownChart from '../indexwizard/DrawdownChart'
import ContributionChart from '../indexwizard/ContributionChart'
import TotalTurnoverChart from '../indexwizard/TotalTurnoverChart'
import TurnoverChart from '../indexwizard/TurnoverChart'
import WeightingPie from '../indexwizard/WeightingPie'
import WeightingDataGrid from '../indexwizard/WeightingDataGrid'
import IntroDialog from '../indexwizard/IntroDialog'
import BasketUpload from '../indexwizard/BasketUpload'

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//icons
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

//backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


//dialog
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

//snackbar
import Snackbar from '@mui/material/Snackbar';

/*
async function getResult(payload){
  console.log("GETTING RES")
  try {
    const response = await api.post(`backtest`,payload)
    const data = response.data
    //alert("RUNDONE")
    return response
  } catch (error) {
    if(error.response){
      return error.response
    }else{
      return {status:504,message:'Initial request timed out, you can still proceed to backtest'}
    }
  }
}
*/


const weightingColumns = [
  { field: 'id', headerName: 'id',width:120},
  { field: 'weight', headerName: 'weight', flex:2}
];

const basePayload = {

}

/*
const initFilters = [
  {
    id:"0",
    attribute:'iex_sector',
    operator:'=',
    value:'Information'
  },
  {
    id:"1",
    attribute:'issue_type',
    operator:'=',
    value:'cs'
  },
  {
    id:"2",
    attribute:'region',
    operator:'=',
    value:'US'
  },
  {
    id:"3",
    attribute:'country',
    operator:'=',
    value:'US'
  },
  {
    id:"3",
    attribute:'mic',
    operator:'in',
    value:['XNYS','XNAS']
  },
  {
    id:"4",
    attribute:'market_cap',
    operator:'limit_descending',
    value:100
  }
]
*/
/*
const initFilters = [
    {
      "id": "0",
      "attribute": "iex_symbol",
      "operator": "in",
      "value": [
        "GS",
        "MA",
        "VZ"
      ]
    },
    {
      "id": "1",
      "attribute": "market_cap",
      "operator": ">",
      "value": 100000
    },
    {
      "id": "1",
      "attribute": "market_cap",
      "operator": "limit_descending",
      "value": 5
    }
]
*/

const initFilters = [
  {
    "id": "0",
    "attribute": {"type":"security_type"},
    "operator": "=",
    "value": "Common Stock"
  },
  {
    "id": "1",
    "attribute": {"type":"mic"},
    "operator": "in",
    "value": [
      "XNYS",
      "XNAS"
    ]
  },
  {
    "id": "3",
    "attribute": {"type":"market_cap"},
    "operator": "limit_descending",
    "value": 50
  }
]

const defaultSpec = {
  "preview":true,
  "template_version":"2.0",
  "name":"default index namez",

  //which mode to use for each
  "reconstitution_mode":"default",
  "rebalancing_mode":"default",
  "reconstitution_schedule_mode":"default",
  "rebalancing_schedule_mode":"default",

  //file spec
  "file_config":{},
  "file_name":null,
  "file_url":"",

  //code spec
  "code_config":{},
  "code_name":null,
  "code_url":"",

  //recon spec
  "reconstitution_trigger":{},
  "reconstitution_config":{
    "filters":initFilters
  },
  //weighting spec
  "rebalancing_trigger":{},
  "rebalancing_config":{
      'type':'equal',
      'attribute':'market_cap',
      "constraints":[]
  },
  "effective_trigger":{},




  "full_backtest": false,
  "benchmark":[
    "BBG000BDTBL9",
    "BBG000GM5FZ6"
  ],
  "start_time": "2020-01-01",
  "schedule":"0 16 1 1 *",
  "calculation_frequency":"eod",
  "start_value":1000,
  "base_currency":"local",
  "open_time":"09:30",
  "close_time":"16:00",
  "eod_time":"16:00",
  "timezone":"US/Eastern",
  "timezone_open":"US/Eastern",
  "timezone_close":"US/Eastern",
  "visibility":"team",
  "adjustment_policies": {
    "dividend": {
      "deployment_scheme": "portfolio",
      "deploy_at": {
        "expression_type": "date",
        "value": "ex_date"
      }
    },
    "split":{
      "deployment_scheme": "security",
      "expression_type":"date",
      "value":"ex_date",
      "deploy_at":{
        "expression_type": "date",
        "value": "ex_date"
      }
    }
  },
  "reconstitution":[{
    "trigger":{
      "schedule":{
        "type":"cron",
        "value":"0 16 1 1 *"
      }
    },
    "universe":[
      {
        "id": "0",
        "attribute": "security_type",
        "operator": "=",
        "value": "Common Stock"
      },
      {
        "id": "1",
        "attribute": "exchange_name",
        "operator": "in",
        "value": [
          "Nasdaq All Markets",
          "New York Stock Exchange Inc"
        ]
      },
      {
        "id": "2",
        "attribute": "sector",
        "operator": "in",
        "value": [
          "Information",
          "Technology Services",
          "Finance and Insurance"
        ]
      },
      {
        "id": "3",
        "attribute": "market_cap",
        "operator": "limit_descending",
        "value": 100
      }
    ],
    "weighting":{
      "objectives":[
        {'type':'equal','attribute':'market_cap'}
      ],
      "constraints":[

      ]
    }
  }]
}

export default function IndexConfiguration(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [indexSpecifications,setIndexSpecifications] = useState(defaultSpec)

  const handleChange = (newParams) => {
    //compound changes
    if(newParams.reconstitution_config){newParams.reconstitution_mode = "default"}
    if(newParams.rebalancing_config){newParams.rebalancing_mode = "default"}
    console.log('pchange')
    console.log({...indexSpecifications,...newParams})
    setIndexSpecifications({...indexSpecifications,...newParams})
  }

  const testChange = () => {
    var newSpec = JSON.parse(JSON.stringify(indexSpecifications))
    newSpec.reconstitution[0].universe[3].value = Math.floor(Math.random() * 100)
    setIndexSpecifications(newSpec)
  }

  const returnWebsocketId = () => {

  }

  const runBacktest = () => {
    props.runBacktest(indexSpecifications)
  }

  const teamId = useSelector(state => state.activeTeamId)
  //const teamName = useSelector(state => state.activeTeamName)
  //const teamSubscription = useSelector(state => state.activeTeam.subscription)
  const teamData = useSelector(state => state.activeTeam)

  return (
    (<Container maxWidth={false} style={{ padding: '1em', backgroundColor:'#121212', height:'calc(100vh  - 5em)' }} disableGutters>
      <Grid container spacing={4} sx={{height:'100%'}}>
        <Grid
          sx={{height:'100%'}}
          size={{
            xs: 12,
            sm: 9
          }}>
          <Paper sx={{height:'100%'}}>
            <IndexSetup
              indexSpecifications={indexSpecifications}
              setIndexSpecifications={setIndexSpecifications}
              handleChange={handleChange}
              testChange={testChange}
            />
          </Paper>
        </Grid>
        <Grid
          sx={{height:'100%'}}
          size={{xs: 12,sm: 3}}>
          <Paper sx={{height:'100%'}}>
            <IndexPreview
              indexSpecifications={indexSpecifications}
              setIndexSpecifications={setIndexSpecifications}
              runBacktest={runBacktest}
              handleChange={handleChange}
              testChange={testChange}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>)
  );
}